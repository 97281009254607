import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Categories from './Categories'

const Item = styled.li`
  margin-bottom: 1.45rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  font-size: 1rem;
  padding-top: .5rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

export default class LenderListItem extends Component {
  render() {
    const { node, categories } = this.props
    return (
              <div className="column is-3">
                <article className="equal-height">
                  <Link to={[node.data.categories[0].category.slug + '/' + node.uid]}>
                  <img className="lender-image lender-image-small box cardy is-marginless"
                       src={node.data.image.url}
                       alt={node.data.image.alt}
                  />
                  </Link>
                  <StyledLink to={[node.data.categories[0].category.slug + '/' + node.uid]}>{node.data.lender}</StyledLink>
                </article>
              </div>
    )
  }
}

LenderListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
