import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Listing, Wrapper, Title } from '../components'
import website from '../../config/website'
import LenderListing from '../components/Listing/LenderListing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCalculator } from "@fortawesome/free-solid-svg-icons";

const Hero = styled.header`
  background-image: url('/img/background-pattern.svg');
  background-color: #4675c3;
  display: flex;
  align-items: center;
`

const HeroInner = styled(Wrapper)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  h1 {
    margin-bottom: 2rem;
    color: white;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const HeroText = styled.div`
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: white;
  padding: '0.25em',
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const IndexWrapper = Wrapper.withComponent('main')

class Index extends Component {
  render() {
    const {
      data: { homepage, social, posts, projects, reviews },
    } = this.props
    return (
      <Layout>
        <Hero>
          <HeroInner>
            <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'white',
                  lineHeight: '1',
                  padding: '0.25em',
                }}
            >
              {homepage.data.title.text}</h1>
            <HeroText dangerouslySetInnerHTML={{ __html: homepage.data.content.html }} />
            <Link to="/student-loans">
              <a className="button home is-info is-large">Student Loan 101</a>
            </Link>
          </HeroInner>
        </Hero>


            <section className="hero is-light">
              <div className="hero-body">
                <div className="container">
                  <div className="columns is-multiline is-centered">
                    <div className="column is-4 has-text-centered">

                      <div className="icon is-large">
                        <svg className="svg-inline--fa fa-copy fa-w-14 fa-4x" aria-hidden="true" data-prefix="fas"
                             data-icon="copy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                             data-fa-i2svg="">
                          <path fill="currentColor"
                                d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                        </svg>
                      </div>

                      <p className="title is-5">Expert Info</p>
                      <p className="subtitle is-5">Our fantastic editorial team of review and answer the most complicated refinance questions and topics.</p>


                    </div>

                    <div className="column is-4 has-text-centered">

                      <div className="icon is-large">
                        <svg className="svg-inline--fa fa-mail-bulk fa-w-18 fa-4x" aria-hidden="true" data-prefix="fas"
                             data-icon="mail-bulk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                             data-fa-i2svg="">
                          <path fill="currentColor"
                                d="M160 448c-25.6 0-51.2-22.4-64-32-64-44.8-83.2-60.8-96-70.4V480c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32V345.6c-12.8 9.6-32 25.6-96 70.4-12.8 9.6-38.4 32-64 32zm128-192H32c-17.67 0-32 14.33-32 32v16c25.6 19.2 22.4 19.2 115.2 86.4 9.6 6.4 28.8 25.6 44.8 25.6s35.2-19.2 44.8-22.4c92.8-67.2 89.6-67.2 115.2-86.4V288c0-17.67-14.33-32-32-32zm256-96H224c-17.67 0-32 14.33-32 32v32h96c33.21 0 60.59 25.42 63.71 57.82l.29-.22V416h192c17.67 0 32-14.33 32-32V192c0-17.67-14.33-32-32-32zm-32 128h-64v-64h64v64zm-352-96c0-35.29 28.71-64 64-64h224V32c0-17.67-14.33-32-32-32H96C78.33 0 64 14.33 64 32v192h96v-32z"></path>
                        </svg>
                      </div>

                      <p className="title is-5">Useful Tools</p>
                      <p className="subtitle is-5">Reviews, comparison tools, and calculators help you make sense of your loan options.</p>


                    </div>

                    <div className="column is-4 has-text-centered">

                      <FontAwesomeIcon size="2x" icon={faCalculator} />


                      <p className="title is-5">Tailored Data</p>
                      <p className="subtitle is-5">We know what to look for when it comes to your options. We provide this data in a clear and concise format.</p>


                    </div>

                  </div>
                  <h2 className="title has-text-centered is-hidden-mobile" style={{ marginTop: '3rem', marginBottom: '3rem' }}>Student Loan Refinance Reviews</h2>
                  <LenderListing reviews={reviews.nodes} />
                </div>
              </div>
            </section>

        <IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Title style={{ marginTop: '4rem' }}>Recent Articles</Title>
          <Listing posts={posts.nodes} />
        </IndexWrapper>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    social: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    reviews: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    social: allPrismicHeroLinksBodyLinkItem {
      nodes {
        primary {
          label {
            text
          }
          link {
            url
          }
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }, filter: {data: {categories: {elemMatch: {category: {slug: {eq: "student-loans"}}}}}}) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          categories {
            category {
              slug
              document {
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
    reviews:   allPrismicLenderreview(filter: {tags: {in: "refinance"}, data: {categories: {elemMatch: {category: {slug: {eq: "student-loans"}}}}}}) {
    nodes {
      uid
      data {
        image {
          url
        }
        lender
        lenderafflink {
          url
        }
        categories {
          category {
            slug
            id
            document {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
    projects: allPrismicProjectsBodyLinkItem {
      nodes {
        primary {
          label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`
