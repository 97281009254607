import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LenderListItem from './LenderListItem'

export default class LenderListing extends Component {
  render() {
    const { reviews } = this.props
    return (
      <div className="columns is-multiline is-hidden-mobile">
        {reviews.map(post => {
          let categories = false
          if (post.data.categories[0].category) {
            categories = post.data.categories.map(c => c.category.document[0].data.name)
          }
          return <LenderListItem key={post.uid} node={post} categories={categories} />
        })}
      </div>
    )
  }
}

LenderListing.propTypes = {
  reviews: PropTypes.array.isRequired,
}
